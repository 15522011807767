window.addEventListener('DOMContentLoaded', run);

const loader = document.querySelector('.loader');
const submitButton = document.querySelector('.primary-btn');
const successMessage = document.querySelector('.success-message');
const errorMessage = document.querySelector('.error-message');
const innerForm = document.querySelector('.inner-contact-form');

function run(e) {
    choosenFile();
    initFormSecret();
}

function initFormSecret() {
    setTimeout(() => {
        let parent = document.createElement('div');
        let i = 0;

        document.body.appendChild(parent);

        while (i < 5) {
            const child = document.createElement('div');
            parent.appendChild(child);
            parent = child;
            i++;
        }

        parent.classList.add('xyz');
        parent.dataset.xyz =
            rand(1, 'zrtef') +
            rand(9, '13abcdefgzxcv_') +
            rand(2, '0123456789') +
            rand(5, 'asdfghjklmnb');
    }, 2000);
}

function rand(length, characters) {
    let result = '';
    const len = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * len));
    }
    return result;
}

function choosenFile() {
    const actualBtn = document.getElementById('contact-attachment');
    const fileChosen = document.getElementById('file-chosen');

    if (actualBtn) {
        actualBtn.addEventListener('change', function () {
            fileChosen.textContent = this.files[0].name;
        });
    }
}

export default function handleFormSubmit({
    selector,
    uri,
    configKey,
    onLoading = state => {
        console.log(state ? 'started loading' : 'stopped loading');
        loader.classList.toggle('show');
        submitButton.classList.toggle('hidden');
    },
    onSuccess = data => {
        console.log('form success', data);
        successMessage.classList.toggle('hidden');
        innerForm.classList.toggle('hidden');
    },
    onFailure = data => {
        console.log('form failure', data);
        errorMessage.classList.toggle('hidden');
        innerForm.classList.toggle('hidden');
    },
    onError = error => {
        console.log('form error', error);
        errorMessage.classList.toggle('hidden');
        innerForm.classList.toggle('hidden');
    }
}) {
    if (typeof onSuccess !== 'function') {
        onSuccess = () => '';
    }

    if (typeof onFailure !== 'function') {
        onFailure = () => '';
    }

    if (typeof onError !== 'function') {
        onError = () => '';
    }

    if (typeof onLoading !== 'function') {
        onLoading = () => '';
    }

    document.querySelector(selector).addEventListener('submit', async e => {
        e.preventDefault();

        const formData = new FormData();
        const date = new Date();
        const dateSum =
            date.getUTCFullYear() + date.getUTCMonth() + 1 + date.getUTCDate() + date.getUTCHours();
        const answer = !(dateSum % 2) ? 'yes' : 'no';
        const xyz = Object(Object(document.querySelector('.xyz')).dataset).xyz;

        formData.append('answer', answer);
        formData.append('xyz', xyz);
        if (configKey) {
            formData.append('configKey', configKey);
        }

        for (let i = 0, ii = e.target.elements.length; i < ii; i++) {
            const element = e.target.elements[i];

            if (element.type === 'submit') {
                continue;
            }

            if (element.type === 'file') {
                formData.append(element.name || 'file', element.files[0]);
            } else {
                formData.append(element.name || element.id || element.tagName + i, element.value);
            }
        }

        onLoading.call(this, true);

        try {
            const response = await fetch(uri, {
                method: 'POST',
                body: formData,
                referrerPolicy: 'strict-origin-when-cross-origin'
            });

            const data = await response.json();

            if (data.success) {
                onSuccess.call(this, data);
            } else {
                onFailure.call(this, data);
            }
        } catch (error) {
            onError.call(this, error);
        }

        onLoading.call(this, false);
    });
}
