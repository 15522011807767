const hasRedirected = localStorage.getItem('hasRedirected');

if (hasRedirected !== 'true') {
    const userLanguage = navigator.language || navigator.userLanguage;

    if (userLanguage.toLowerCase().startsWith('ja')) {
        window.location.href = '/jp.html';
        localStorage.setItem('hasRedirected', 'true');
    }
}
